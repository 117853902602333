import { template as template_bfe8d67995744189a532d8a6c673be01 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_bfe8d67995744189a532d8a6c673be01(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
