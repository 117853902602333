import { template as template_92167b6b7f164d9abe3f5a0664d221c4 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_92167b6b7f164d9abe3f5a0664d221c4(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
