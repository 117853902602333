import { template as template_285e30b996a041deb7914b1b7d2a4a89 } from "@ember/template-compiler";
const SidebarSectionMessage = template_285e30b996a041deb7914b1b7d2a4a89(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
