import { template as template_4933b491ef8d4416a2401b2cd09669a2 } from "@ember/template-compiler";
const WelcomeHeader = template_4933b491ef8d4416a2401b2cd09669a2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
