import { template as template_18f9d6141a4648c9935b8a15b69de774 } from "@ember/template-compiler";
const FKLabel = template_18f9d6141a4648c9935b8a15b69de774(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
